import { jsx as t, Fragment as n } from "react/jsx-runtime";
import { Checkbox as s } from "primereact/checkbox";
import { useStateTogether as l } from "react-together";
function u({
  rtKey: o,
  ...e
}) {
  const [r, a] = l(o, !1);
  return /* @__PURE__ */ t(n, { children: /* @__PURE__ */ t(
    s,
    {
      ...e,
      onChange: (c) => a(c.checked || !1),
      checked: r,
      className: `outline outline-1 outline-slate-400 rounded ${e.className}`
    }
  ) });
}
export {
  u as default
};
