import { jsx as n } from "react/jsx-runtime";
import { Rating as u } from "primereact/rating";
import { useStateTogether as i } from "react-together";
function g({
  rtKey: e,
  ...t
}) {
  const [o, r] = i(e, null);
  return /* @__PURE__ */ n(
    u,
    {
      ...t,
      value: o || void 0,
      onChange: (a) => r(a.value)
    }
  );
}
export {
  g as default
};
