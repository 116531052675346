var r = Object.defineProperty;
var d = (i, s, t) => s in i ? r(i, s, { enumerable: !0, configurable: !0, writable: !0, value: t }) : i[s] = t;
var o = (i, s, t) => (d(i, typeof s != "symbol" ? s + "" : s, t), t);
import { ReactModel as l } from "@croquet/react";
class g extends l {
  constructor() {
    super(...arguments);
    o(this, "state");
    o(this, "stateTogether");
  }
  init(t) {
    super.init({ ...t, trackViews: !0 }), this.state = /* @__PURE__ */ new Map(), this.stateTogether = /* @__PURE__ */ new Map(), this.subscribe(this.id, "setState", this.setState), this.subscribe(this.id, "setStateTogether", this.setStateTogether), this.subscribe(this.id, "functionTogether", this.functionTogether);
  }
  setState({ id: t, newValue: e }) {
    e === void 0 ? this.state.delete(t) : this.state.set(t, e), this.publish(t, "updated", {});
  }
  setStateTogether({ id: t, viewId: e, newValue: a }) {
    let h = this.stateTogether.get(t);
    h === void 0 && (h = /* @__PURE__ */ new Map()), a === void 0 ? h.delete(e) : h.set(e, a), this.stateTogether.set(t, h), this.publish(t, "updated", {});
  }
  functionTogether({ rtKey: t, args: e }) {
    this.publish(t, "call", e);
  }
  handleViewExit(t) {
    this.stateTogether.forEach((e, a) => {
      e.delete(t), this.publish(a, "updated", {});
    });
  }
}
g.register("ReactTogetherModel");
export {
  g as default
};
