import { useCroquetContext as c } from "@croquet/react";
import { useState as f, useEffect as b, useCallback as h } from "react";
import m from "./getNewValue.js";
function C(t, o, p = {}) {
  const { session: i, view: s, model: e } = c(), [d, a] = f(() => !s || !e ? o : e.state.has(t) ? e.state.get(t) : (s.publish(e.id, "setState", { id: t, newValue: o }), o));
  b(() => {
    if (!i || !s || !e)
      return;
    const u = () => {
      a((n) => {
        if (!e.state.has(t))
          return s.publish(e.id, "setState", { id: t, newValue: n }), n;
        const r = e.state.get(t);
        return n === r ? n : r;
      });
    };
    return s.subscribe(
      t,
      { event: "updated", handling: "oncePerFrame" },
      u
    ), u(), () => s.unsubscribe(t, "updated", u);
  }, [i, s, e, t, a]);
  const l = h(
    (u) => {
      if (e && s) {
        const n = e.state.get(t);
        s.publish(e.id, "setState", {
          id: t,
          newValue: m(n, u)
        });
      } else
        a(u);
    },
    [a, e, s, t]
  );
  return [d, l];
}
export {
  C as default
};
