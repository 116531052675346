import { useCroquetContext as s } from "@croquet/react";
import { useEffect as r, useCallback as f } from "react";
function m(o, n) {
  const { view: e, model: t } = s();
  return r(() => {
    if (e) {
      const u = (i) => n(...i);
      return e.subscribe(o, "call", u), () => e.unsubscribe(o, "call", u);
    }
  }, [o, e, n]), f(
    (...u) => {
      e && t ? e.publish(t.id, "functionTogether", { rtKey: o, args: u }) : n(...u);
    },
    [o, e, t, n]
  );
}
export {
  m as default
};
