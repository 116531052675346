import { jsx as n } from "react/jsx-runtime";
import { ColorPicker as v } from "primereact/colorpicker";
import { useState as V, useCallback as c, useEffect as k } from "react";
import { useStateTogether as x } from "react-together";
function j({
  rtKey: f,
  publishWhileOpen: r = !1,
  onChange: o,
  ...i
}) {
  const [a, t] = V(void 0), [u, l] = x(
    f,
    void 0
  ), s = i.inline, m = c(
    (e) => {
      e.value === null && (e.value = void 0), s || r ? l(e.value) : t(e.value), o == null || o(e);
    },
    [l, t, s, r, o]
  ), d = c(() => {
    l(a);
  }, [l, a]);
  return k(() => {
    t(u);
  }, [u, t]), /* @__PURE__ */ n(
    v,
    {
      ...i,
      value: a,
      onChange: m,
      onHide: d
    }
  );
}
export {
  j as default
};
