import { jsx as g } from "react/jsx-runtime";
import { ToggleButton as u } from "primereact/togglebutton";
import { useStateTogether as a } from "react-together";
function s({
  rtKey: e,
  ...t
}) {
  const [o, r] = a(e, !1);
  return /* @__PURE__ */ g(
    u,
    {
      ...t,
      checked: o,
      onChange: (n) => r(n.value)
    }
  );
}
export {
  s as default
};
