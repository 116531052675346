import { useSetSession as e, App as o } from "@croquet/react";
import { useCallback as n } from "react";
function t() {
  const s = e();
  return n(() => {
    s({ name: o.randomSession(), password: o.randomPassword() });
  }, [s]);
}
export {
  t as default
};
