import { jsx as p } from "react/jsx-runtime";
import { CroquetRoot as w } from "@croquet/react";
import f from "../models/ReactTogetherModel.js";
import { getSessionNameFromUrl as h, getSessionPasswordFromUrl as u } from "../utils/urls.js";
function x({
  children: s,
  sessionParams: o
}) {
  const { appId: i, apiKey: a, sessionIgnoresUrl: c } = o;
  let e;
  c || (e = {
    sessionUrl: window.location.origin + window.location.pathname
  });
  const t = new URL(window.location.href), d = h(t), m = u(t), l = o.model || f, r = d || o.name, n = m || o.password;
  return /* @__PURE__ */ p(
    w,
    {
      sessionParams: { model: l, name: r, password: n, appId: i, apiKey: a, options: e },
      deferSession: !r || !n,
      showChildrenWithoutSession: !0,
      children: s
    }
  );
}
export {
  x as default
};
