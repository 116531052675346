import { jsx as u } from "react/jsx-runtime";
import { Dropdown as a } from "primereact/dropdown";
import { useStateTogether as l } from "react-together";
function s({
  rtKey: o,
  ...e
}) {
  const [r, t] = l(o, null);
  return /* @__PURE__ */ u(a, { ...e, value: r, onChange: (n) => t(n.value) });
}
export {
  s as default
};
