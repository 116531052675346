import { jsx as n } from "react/jsx-runtime";
import { InputSwitch as h } from "primereact/inputswitch";
import { useStateTogether as u } from "react-together";
function m({
  rtKey: e,
  ...t
}) {
  const [o, r] = u(e, !1);
  return /* @__PURE__ */ n(
    h,
    {
      ...t,
      checked: o,
      onChange: (c) => r(c.value)
    }
  );
}
export {
  m as default
};
