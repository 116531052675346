import { useJoinedViews as t, useViewId as a } from "@croquet/react";
import { uniqueNamesGenerator as n, adjectives as o, animals as i } from "unique-names-generator";
function c() {
  const { views: r } = t(), s = a();
  return Array.from(r).map((e) => ({
    userId: e,
    isYou: e === s,
    name: n({
      seed: e,
      dictionaries: [o, i],
      length: 2,
      separator: " ",
      style: "capital"
    })
  }));
}
export {
  c as default
};
