import { jsx as m } from "react/jsx-runtime";
import { SelectButton as o } from "primereact/selectbutton";
import { useStateTogether as s } from "react-together";
function p({
  rtKey: r,
  options: e,
  ...a
}) {
  const [l, u] = s(r, null);
  return /* @__PURE__ */ m(
    o,
    {
      ...a,
      onChange: (t) => u(t.value || !1),
      options: e,
      value: l || (e == null ? void 0 : e[0]),
      pt: {
        button: (t) => ({
          className: `border h-[15px] ${(t || { props: {} }).props.className}`
        })
      }
    }
  );
}
export {
  p as default
};
