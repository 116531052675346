function h(n, s) {
  return n.searchParams.get(s);
}
function u(n, s, t) {
  n.searchParams.set(s, t);
}
function m(n, { key: s, scrub: t = !1 } = {}) {
  if (!n.hash)
    return null;
  const r = n.hash.slice(1);
  if (!r)
    return null;
  const a = r.split("&");
  if (s) {
    const e = a.find((i) => i.split("=")[0] === s);
    if (e) {
      const [, ...i] = e.split("=");
      return t && (n.hash = a.filter((l) => l.split("=")[0] !== s).join("&")), i.join("=");
    }
  } else {
    const e = a.find((i) => !i.includes("="));
    if (e)
      return t && (n.hash = a.filter((i) => i !== e).join("&")), e;
  }
  return null;
}
function f(n, s, t) {
  t && t.includes("&") && (console.warn(
    `Given value "${t}" contains invalid characters. Trimming until &`
  ), t = t.split("&")[0]);
  const r = s === null, a = n.hash.slice(1), e = a ? a.split("&").filter(Boolean) : [], i = r ? e.filter((l) => l.includes("=")) : e.filter((l) => l.split("=")[0] !== s);
  if (t !== null) {
    const l = r ? t : `${s}=${t}`;
    i.push(l);
  }
  n.hash = i.join("&");
}
const o = "rtName", c = "rtPwd";
function P(n, { nameKey: s = o } = {}) {
  return h(n, s);
}
function S(n, {
  passwordKey: s = c
} = {}) {
  return m(n, { key: s });
}
function p(n, s, t, {
  nameKey: r = o,
  passwordKey: a = c
} = {}) {
  const e = new URL(n);
  return u(e, r, s), f(e, a, t), e;
}
function U(n, {
  nameKey: s = o,
  passwordKey: t = c
} = {}) {
  const r = new URL(n);
  return r.searchParams.delete(s), f(r, t, null), r;
}
export {
  U as getCleanUrl,
  p as getJoinUrl,
  P as getSessionNameFromUrl,
  S as getSessionPasswordFromUrl,
  m as getUrlHashParam,
  h as getUrlSearchParam,
  f as setUrlHashParam,
  u as setUrlSearchParam
};
