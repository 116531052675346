function u(t) {
  return typeof t == "function";
}
function f(t, e) {
  let n;
  return u(e) ? n = e(t) : n = e, n;
}
export {
  f as default,
  u as isFunction
};
