import { useCroquetContext as C, useViewId as E } from "@croquet/react";
import { h as g } from "../object_hash-1efe2599.js";
import { useState as w, useEffect as I, useCallback as L } from "react";
import m from "./getNewValue.js";
const r = Object.freeze({});
function p(e) {
  return Object.fromEntries(e.entries());
}
function O(e, b) {
  const [n] = w(b), { session: h, view: a, model: t } = C(), l = E(), [T, i] = w(() => {
    if (!a || !t || !l)
      return {
        localValue: n,
        allValues: r,
        allValuesHash: null
      };
    const s = p(
      t.stateTogether.get(e) ?? /* @__PURE__ */ new Map([[l, n]])
    );
    return {
      localValue: n,
      allValues: s,
      allValuesHash: g(s)
    };
  });
  I(() => {
    if (!h || !a || !t || !l) {
      i((u) => ({
        localValue: u.localValue,
        allValues: r,
        allValuesHash: null
      }));
      return;
    }
    const s = () => {
      i((u) => {
        const { localValue: o, allValuesHash: V } = u, c = new Map(t.stateTogether.get(e));
        c.has(l) || (a.publish(t.id, "setStateTogether", {
          id: e,
          viewId: l,
          newValue: o
        }), c.set(l, o));
        const d = p(c), f = g(d);
        return V === f ? u : {
          localValue: c.get(l),
          allValues: d,
          allValuesHash: f
        };
      });
    };
    return a.subscribe(
      e,
      { event: "updated", handling: "oncePerFrame" },
      s
    ), s(), () => {
      a.publish(t.id, "setStateTogether", {
        id: e,
        viewId: l,
        newValue: void 0
      }), a.unsubscribe(e, "updated", s);
    };
  }, [h, a, l, t, e]);
  const S = L(
    (s) => {
      if (!a || !l || !t)
        i((u) => {
          const o = m(u.localValue, s);
          return u.localValue === o ? u : {
            localValue: o,
            allValues: r,
            allValuesHash: null
          };
        });
      else {
        let o = t.stateTogether.get(e).get(a.viewId);
        o === void 0 && (console.warn(
          `[useStateTogetherWithPerUserValues:setter] prevLocalValue is undefined.Using initialValue: ${n}`
        ), o = n);
        const V = m(o, s);
        a.publish(t.id, "setStateTogether", {
          id: e,
          viewId: l,
          newValue: V
        });
      }
    },
    [a, l, t, e, n]
  ), { localValue: H, allValues: v } = T;
  return [H, S, v];
}
export {
  O as default
};
