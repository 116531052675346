import { jsxs as n, Fragment as h, jsx as e } from "react/jsx-runtime";
import { Avatar as a } from "primereact/avatar";
import { AvatarGroup as d } from "primereact/avatargroup";
import f from "../hooks/useConnectedUsers.js";
import "@croquet/react";
import "react";
import "../object_hash-1efe2599.js";
function s({ name: t }) {
  return /* @__PURE__ */ e(
    a,
    {
      image: `https://api.dicebear.com/8.x/initials/svg?seed=${t}`,
      size: "normal",
      shape: "circle",
      label: t,
      pt: { root: { title: t } }
    }
  );
}
function U({
  maxAvatars: t = 3,
  debug: c = !1
}) {
  const r = f(), p = "normal", o = Math.max(r.length - (t - 1), 0);
  return /* @__PURE__ */ n(h, { children: [
    /* @__PURE__ */ n(d, { pt: { root: { style: { gap: "10px" } } }, children: [
      r.slice(0, t - 1).map(({ name: i, userId: l }) => /* @__PURE__ */ e(s, { name: i }, l)),
      o > 0 && (o === 1 ? /* @__PURE__ */ e(s, { name: r[r.length - 1].name }) : /* @__PURE__ */ e(a, { label: `+${o}`, shape: "circle", size: p }))
    ] }),
    c && /* @__PURE__ */ n("div", { style: { textAlign: "left" }, children: [
      /* @__PURE__ */ e("p", { children: "Connected users:" }),
      /* @__PURE__ */ e("ul", { children: r.map(({ userId: i, name: l, isYou: m }) => /* @__PURE__ */ e("li", { children: m ? /* @__PURE__ */ n("strong", { children: [
        i,
        ": ",
        l
      ] }) : /* @__PURE__ */ n("span", { children: [
        i,
        ": ",
        l
      ] }) }, i)) })
    ] })
  ] });
}
export {
  U as default
};
