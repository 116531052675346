import { jsx as l } from "react/jsx-runtime";
import { MultiSelect as a } from "primereact/multiselect";
import { useStateTogether as i } from "react-together";
function s({
  rtKey: e,
  ...t
}) {
  const [o, r] = i(e, []);
  return /* @__PURE__ */ l(
    a,
    {
      ...t,
      value: o,
      onChange: (u) => r(u.value)
    }
  );
}
export {
  s as default
};
