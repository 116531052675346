import { useLeaveSession as s } from "@croquet/react";
import { useCallback as t } from "react";
import { getCleanUrl as n } from "../utils/urls.js";
function l() {
  const e = s();
  return t(() => {
    const o = n(new URL(window.location.href));
    window.history.replaceState({}, "", o.toString()), e();
  }, [e]);
}
export {
  l as default
};
