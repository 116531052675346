import { jsx as n } from "react/jsx-runtime";
import { TabView as s } from "primereact/tabview";
import { useStateTogether as i } from "react-together";
function x({
  rtKey: e,
  ...t
}) {
  const [a, r] = i(e, 0);
  return /* @__PURE__ */ n(
    s,
    {
      ...t,
      activeIndex: a,
      onTabChange: (o) => r(o.index),
      pt: {
        panelContainer: { className: "bg-transparent" },
        root: {
          className: "border-gray-700 shadow-md px-4 rounded-xl"
        }
      }
    }
  );
}
export {
  x as default
};
