import { jsxs as p, Fragment as f, jsx as d } from "react/jsx-runtime";
import { useViewId as u } from "@croquet/react";
import g from "color-hash";
import "unique-names-generator";
import "react";
import h from "../hooks/useHoveringUsers.js";
import "../object_hash-1efe2599.js";
const x = new g();
function F({
  rtKey: o,
  children: n,
  className: l,
  highlightMyself: r = !1
}) {
  const [m, e, a] = h(o), c = u();
  let i = {};
  const s = r ? e : e.filter((t) => t !== c);
  return (s.length > 0 || r && a) && (i = {
    outline: `2px solid ${x.hex(s[0] ?? o)}`,
    animation: "clippath 3s linear infinite",
    borderRadius: "10px"
  }), /* @__PURE__ */ p(f, { children: [
    /* @__PURE__ */ d("div", { ref: m, style: i, className: l, children: n }),
    !1
  ] });
}
export {
  F as default
};
