import { useRef as l, useEffect as c } from "react";
import "@croquet/react";
import "unique-names-generator";
import d from "./useStateTogetherWithPerUserValues.js";
function L(s, m = {}) {
  const o = l(null), [v, r, a] = d(s, !1);
  c(() => {
    const e = o.current;
    if (!e)
      return;
    const t = (i) => {
      const u = i.rtProcessedBy;
      u === void 0 ? (r(!0), i.rtProcessedBy = s) : u !== s && r(!1);
    }, n = () => r(!1);
    return e.addEventListener("mouseover", t), e.addEventListener("mouseleave", n), () => {
      e.removeEventListener("mouseover", t), e.removeEventListener("mouseleave", n);
    };
  }, [r, s]);
  const f = Object.entries(a).filter(([, e]) => e).map(([e]) => e);
  return [o, f, v];
}
export {
  L as default
};
